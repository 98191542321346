import slave from '@nsoft/seven-gravity-gateway/slave';
import Scan from '@nsoft/seven-gravity-gateway/plugin-barcode-scan';
import { debounce } from 'underscore';

/**
   *
  * @class IframePubSub
  * @memberOf module:"7Shop.IframePubSub"
  */
/* @ngInject */
function IframePubSub($rootScope, $document, switchView, Modal) {
  function dissambleEventMeta(event) {
    var fullEventName = event.slice(3, event.length);
    // split event into namespace and action (Betslip.BetAdded)
    var spaces = fullEventName.split('.');

    return {
      fullEventName: fullEventName, // 7S:Betslip.BetAdded
      namespace: spaces[0], // Betslip
      action: spaces[1] // BetAdded
    };
  }

  $rootScope.$on('7S:Widget.UpdateProductSettings', (e, eventData) => {
    eventData.data.productId = eventData.productId;
    this.publish('Widget.UpdateProductSettings', eventData.data, {
      enforceEvent: true
    });
  });

  $rootScope.$on('7S:Peripherals.Print', (e, eventData) => {
    this.publish('Peripherals.Print', eventData.data, {
      enforceEvent: true
    });
  });

  $rootScope.$on('7S:Tickets.CashOut', (e, eventData) => {
    this.publish('Tickets.CashOut', eventData, {
      enforceEvent: true,
      ...eventData.options
    });
  });

  $rootScope.$on('7S:Peripherals.UpdateConfig', (e, eventData) => {
    eventData.data.productId = eventData.productId;
    this.publish('Peripherals.UpdateConfig', eventData.data, {
      enforceEvent: true
    });
  });

  $rootScope.$on('7S:Tickets.Print', (e, eventData) => {
    this.publish('Tickets.Print', eventData, {
      enforceEvent: true
    });
  });

  $rootScope.$on('7S:Dialog.ShowModal', (e, eventData) => {
    this.publish('Dialog.ShowModal', eventData, {
      enforceEvent: true,
      ...eventData.options
    });
  });

  $rootScope.$on('7S:Dialog.CloseModal', (e, eventData) => {
    this.publish('Dialog.CloseModal', eventData);
  });

  $rootScope.$on('7S:Peripherals.PrintA4', (e, eventData) => {
    this.publish('Peripherals.PrintA4', eventData);
  });

  $rootScope.$on('7S:UI.Show', (e, eventData) => {
    this.publish('UI.Show', eventData);
  });

  $rootScope.$on('7S:UI.Hide', (e, eventData) => {
    this.publish('UI.Hide', eventData);
  });

  $rootScope.$on('7S:Widget.UpdateSettings', (e, eventData) => {
    this.publish('Widget.UpdateSettings', eventData);
  });
  /**
   * Event for updating ticket state
   *
   * @event "7S:Tickets.Update"
   * @type {Object}
   *
   * @property {String} action
   * @property {String} printAction
   * @property {String} ticketData.requestUuid
   * @property {String} ticketData.id
   * @property {String} ticketData.product
   * @property {Object} ticketData.status
   * @property {String} ticketData.status.value
   * @property {String} [showMessage] - show final message to user or skip
   */
  $rootScope.$on('7S:Tickets.Update', (e, eventData) => {
    this.publish('Tickets.Update', eventData, {
      enforceEvent: true,
      ...eventData.options
    });
  });

  $rootScope.$on('7S:EventPublisher.Publish', (e, eventData) => {
    this.publish('EventPublisher.Publish', eventData);
  });

  // list of events to publish to ticket preview
  [
    '7S:Betslip.TicketActivated',
    '7S:Betslip.UpdateSelectedBet',
    '7S:Betslip.BetAdded',
    '7S:Betslip.ReCreate',
    '7S:Betslip.UpdateStake',
    '7S:Betslip.UpdateBets',
    '7S:Betslip.SystemsUpdated',
    '7S:Betslip.Cleared',
    '7S:Betslip.BetSelected',
    '7S:Betslip.TicketStateChanged'
  ].forEach((eventName) => {
    $rootScope.$on(eventName, debounce((e, eventData) => {
      var eventMeta = {};
      eventMeta = dissambleEventMeta(e.name);
      eventData = eventData || {};
      // append action if not set
      if (!eventData.action) {
        eventData.action = eventMeta.action;
      }

      eventData.fullEventName = eventMeta.fullEventName;
      eventData.namespace = eventMeta.namespace;
      eventData.channel = '7Shop' + eventData.namespace;
      this.publish('EventPublisher.Publish', eventData);
    }));
  });

  /**
   * @type {Object}
   * @memberOf module:"7Shop.IframePubSub".IframePubSub
   */
  this._slave = null;
  /**
   * @memberOf module:"7Shop.IframePubSub".IframePubSub
   * @param {Object} config
   * @param {String} config.slaveId - Id of our frame (Shop is slave)
   * @param {Array} config.allowedOrigins - Array of allowed origins
   * @param {Boolean} [config.debug=false] - Ouput debug messages
   */
  this.addFrame = function (config) {
    let e;
    config.plugins = [new Scan()];
    this._slave = slave(config);

    // listen for all events from parent frame and
    // emits them using angularjs pub/sub
    this._slave.on('*', function (event) {
      let shortcut;
      if (!event.data) event.data = {};
      event.data.productId = event.productId;
      switch (event.action) {
      case 'Master.Event':
        // event keys coming from master
        e = $.Event(event.event);
        angular.extend(e, event);
        angular.element($document).trigger(e);
        break;
      case 'Widget.Event':
        shortcut = event.data.shortcut || event.data.action?.shortcut;
        if (shortcut) {
          $rootScope.$broadcast(
            'KeyPressed',
            shortcut.toLowerCase()
          );
        }
        $rootScope.$evalAsync(function () {
          $rootScope.$emit('7S:' + event.action, event.data);
        });
        break;
      case 'User.AuthorizationChanged':
        sessionStorage.shopToken = event.data.user.auth.accessToken;
        break;
      case 'UI.Show':
        if (event.data?.name?.includes('BettingArea')) {
          const systemsModal = Modal.getModal('systemsList');

          switchView.selectService(event.productId);
          if (systemsModal) systemsModal.controller.close();
        } else if (event.data?.name?.includes('GamesHelp')) {
          $rootScope.$emit('7S:Hide.BetslipInfo', {
            productId: event.data.productId
          });
        }
        break;
      case 'Dialog.ShowModal':
      case '7S:Dialog.ShowModal': // prevent loop, since this one is already listened above
        break;
      default:
        $rootScope.$evalAsync(function () {
          $rootScope.$emit('7S:' + event.action, event.data);
        });
      }
    });
  };

  /**
   * Publish message to parent frame
   *
   * @memberOf module:"7Shop.IframePubSub".IframePubSub
   * @param {String} action - Name of actions to publish to upper frame
   * @param {Object} data - Additional data to send
   * @param {Object} options - Gateway options
   */
  this.publish = function (action, data, options) {
    if (options?.async) {
      this._slave.emitAsync({
        action: action,
        enforceEvent: !!options?.enforceEvent,
        data: data
      }).then(data.onSuccess).catch(data.onCatch);
    } else {
      this._slave.emit({
        action: action,
        enforceEvent: !!options?.enforceEvent,
        data: data
      });
    }
  };

  this.getDriver = function () {
    return this._slave;
  };
}

export default IframePubSub;
